<template>
  <el-config-provider :locale="locale">
    <router-view/>
  </el-config-provider>
</template>
<script>
import {ElConfigProvider} from 'element-plus'

import zhCn from 'element-plus/lib/locale/lang/zh-cn'
import {isMobile} from "@/utils/devices";

export default {
  components: {
    [ElConfigProvider.name]: ElConfigProvider,
  },
  data() {
    return {
      locale: zhCn,
    }
  },
  created: function () {
    if (isMobile() && process.env.VUE_APP_MOBILE_ADAPTER) {
      location.href = process.env.VUE_APP_CPS_MOBILE_URL
    }
  }
}
</script>
<style>
@import "assets/css/main.css";
</style>
