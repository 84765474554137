// +-------------------------------------------------------------
// | Created by WebStorm.
// +-------------------------------------------------------------
// | User: msmile
// +-------------------------------------------------------------
// | Date: 2022/8/18
// +-------------------------------------------------------------
// |Time: 13:51
// +-------------------------------------------------------------
import {createStore} from "vuex";
import {logout} from "@/api/login";
import {promoteInfo} from "@/api/home";
import {stsToken} from "@/api/tool";
import {getTime, utcToTimestamp} from "@/utils/time";

export default createStore({
    state:{
        // 菜单栏收缩
        collapse: false,
        // 分帧标签
        tagsList: [],
        // 用户信息
        userInfo: [],
        // 阿里云上传
        stsToken: {
            SecurityToken: '',
            AccessKeyId: '',
            AccessKeySecret: '',
        },
    },
    mutations: {
        login(state, data) {
            localStorage.setItem('promote_token', data.token);
            localStorage.setItem('promote_id', data.id);
        },
        async logout(state, is_req = true) {
            if (is_req) await logout();
            localStorage.removeItem('promote_token')
            localStorage.removeItem('promote_id')
            state.tagsList = [];
            state.userInfo = [];
        },
        updateUserInfo(state,data) {
            state.userInfo = data
        },
        // 删除标签页
        delTagsItem(state, data) {
            state.tagsList.splice(data.index, 1)
        },
        // 增加标签页
        setTagsItem(state, data) {
            state.tagsList.push(data)
        },
        // 清除标签页
        clearTags(state) {
            state.tagsList = []
        },
        // 清除其他标签页
        closeTagsOther(state, data) {
            state.tagsList = data;
        },
        // 关闭当前标签页
        closeCurrentTag(state, data) {
            for (let i = 0, len = state.tagsList.length; i < len; i++) {
                const item = state.tagsList[i];
                if (item.path === data.$route.fullPath) {
                    if (i < len - 1) {
                        data.$router.push(state.tagsList[i + 1].path);
                    } else if (i > 0) {
                        data.$router.push(state.tagsList[i - 1].path);
                    } else {
                        data.$router.push("/");
                    }
                    state.tagsList.splice(i, 1);
                    break;
                }
            }
        },
        // 侧边栏折叠
        handleCollapse(state, data) {
            state.collapse = data;
        }
    },
    getters:{
        async getStsToken(state) {
            // stsToken存在 且当前时间在有效期内
            if (state.stsToken.AccessKeyId && (getTime() + 300) < state.stsToken.Expiration) {
                return state.stsToken
            }
            await stsToken().then(res => {
                state.stsToken.SecurityToken = res.data.Credentials.SecurityToken;
                state.stsToken.AccessKeyId = res.data.Credentials.AccessKeyId;
                state.stsToken.AccessKeySecret = res.data.Credentials.AccessKeySecret;
                state.stsToken.Expiration = utcToTimestamp(res.data.Credentials.Expiration);
            })
            return state.stsToken
        },
    },
    actions:{
        getUserInfo({commit}){
            promoteInfo().then(({data})=>{
                commit('updateUserInfo',data)
            })
        }
    },
    modules:{},
})
