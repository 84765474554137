import {h} from "vue";
import {RouterView} from "vue-router";

export default [
    {
        path: '/dashboard',
        name: "VDashboard",
        meta: {
            title: "渠道首页",
            icon: 'el-icon-yxy-home',
        },
        component: () => import("@/views/Dashboard/Dashboard")
    },
    {
        path: '/game',
        name: "AppGameList",
        meta: {
            title: "游戏列表",
            icon: 'el-icon-yxy-game',
        },
        component: () => import("@/views/game/appGame/List")
    },
    {
        path: '/data/user-white',
        name: "UserWhiteList",
        meta: {
            title: "用户折扣",
            icon: 'el-icon-yxy-zhekou',
        },
        component: () => import("@/views/data/userWhite/List")
    },
    {
        path: '/order',
        name: "Order",
        meta: {
            title: "订单明细",
            icon: 'el-icon-yxy-chongzhijilu',
        },
        component: () => import("@/views/order/gameRecharge/List")
    },
    {
        path: '/data/user',
        name: "DataUserList",
        meta: {
            title: "注册明细",
            icon: 'el-icon-yxy-yonghu1',
        },
        component: () => import("@/views/data/user/List")
    },
    {
        path: '/data/settle',
        name: "Settle",
        meta: {
            title: "结算明细",
            icon: 'el-icon-yxy-jiesuan',
        },
        component: () => import("@/views/order/gameRechargeSettle/List")
    },
    {
        path: '/data/miniGameData',
        name: "MiniGameDataList",
        meta: {
            title: "小游戏数据",
            icon: 'el-icon-yxy-data',
        },
        component: () => import("@/views/data/miniGameData/List")
    },
    {
        path: '/wallet',
        name: "Wallet",
        meta: {
            title: "我的钱包",
            icon: 'el-icon-yxy-Wallet',
        },
        component: {render:() => h(RouterView)},
        children:[
            {
                path: 'log',
                name: "WalletLog",
                meta: {
                    title: "钱包明细"
                },
                component: () => import("@/views/wallet/walletLog/List"),
            },
            {
                path: 'draw',
                name: "WithdrawApply",
                meta: {
                    title: "立即提现"
                },
                component: () => import("@/views/wallet/withdraw/Apply"),
            },
            {
                path: 'withdraw',
                name: "Withdraw",
                meta: {
                    title: "提现管理"
                },
                component: () => import("@/views/wallet/withdraw/List")
            }
        ]
    },
    {
        path: '/user-info',
        name: "UserInfo",
        meta: {
            title: "个人中心",
            icon: 'el-icon-yxy-yonghu3',
        },
        component: () => import("@/views/promote/Info/Index"),
        children:[
            {
                path: '?active=info',
                name: "PromoteInfoBaseInfo",
                meta: {
                    title: "基本信息"
                },
                component: () => import("@/views/promote/Info/Index"),
            },
            {
                path: '?active=settle-account',
                name: "PromoteInfoSettleAccount",
                meta: {
                    title: "结算信息"
                },
                component: () => import("@/views/promote/Info/Index"),
            },
            {
                path: '?active=password',
                name: "PromoteInfoPassword",
                meta: {
                    title: "修改登录密码"
                },
                component: () => import("@/views/promote/Info/Index"),
            },
            {
                path: '?active=second-password',
                name: "PromoteInfoSecondPassword",
                meta: {
                    title: "修改二级密码"
                },
                component: () => import("@/views/promote/Info/Index"),
            }
        ]
    },
    {
        path: "/sham-search",
        name: "ShamSearch",
        meta: {
            title: "钓鱼库",
            icon: "el-icon-yxy-Fishing",
            menu:true,
            level:1
        },
        component: () => import("@/views/ShamSearch")
    },
]
