import axios from 'axios';
import qs from 'qs';
import { makeSign } from "./sign";
import router from "@/router";
import { ElMessage } from "element-plus";
import store from "@/store/index";
import {getLocalStorage} from "@/utils/make";

const filter = function (inObject) {
    const sortedJson = {};
    const sortedKeys = Object.keys(inObject).sort();
    for (let i = 0; i < sortedKeys.length; i++) {
        sortedJson[sortedKeys[i]] = inObject[sortedKeys[i]]
    }
    return sortedJson;
}

const service = axios.create({
    baseURL: process.env.VUE_APP_CPS_API_BASE_URL,
    timeout: 5000,
    headers: {
        'Content-Type': "application/x-www-form-urlencoded"
    },
});
// 设置请求次数，请求的间隙
service.defaults.retry = 4;
service.defaults.retryDelay = 1000;

service.interceptors.request.use(
    config => {
        // 若为请求重试，不做数据处理
        if(!config.__retryCount){
            if (config.method === 'get') {
                config.params = config.params || {}
                config.params = makeSign(config.params)
            } else if (config.method === 'post') {
                if (config.data instanceof FormData) {
                    config.data = makeSign(config.data)
                } else {
                    config.data = config.data || {}
                    config.data = makeSign(config.data) // 参数签名
                    config.data = qs.stringify(filter(config.data)) // 转form表单
                }
            }
            const localStorageData = getLocalStorage();
            config.headers.token = localStorageData.token
            config.headers.promote_id = localStorageData.uid
        }
        return config;
    },
    error => {
        console.log(error);
        return Promise.reject();
    }
)

service.interceptors.response.use(
    response => {
        if (response.status === 200) {
            if (response.data.code === 888) {
                store.commit('logout', false)
                router.push('/login')
            }
            if (response.headers['content-disposition']) {
                const disposition = response.headers['content-disposition'];
                let fileName = decodeURI(escape(disposition.split('filename=')[1].split(';')[0]))

                const blob = new Blob([response.data], { type: response.headers['content-type'] });
                const downloadElement = document.createElement('a');
                const href = window.URL.createObjectURL(blob); // 创建下载的链接
                downloadElement.href = href;
                downloadElement.download = fileName; // 下载后文件名
                document.body.appendChild(downloadElement);
                downloadElement.click(); // 点击下载
                document.body.removeChild(downloadElement); // 下载完成移除元素
                window.URL.revokeObjectURL(href); // 释放掉blob对象

                return response.data
            }
            if (response.data.code !== 200) {
                ElMessage({ message: response.data.message, grouping: true, type: 'error' })
                return Promise.reject(response.data.message)
            }
            return response.data;
        } else {
            ElMessage.error("系统错误")
            return Promise.reject('请求失败');
        }
    },
    error => {
        //console.log(error);
        //网络超时异常处理
        if (error.code === 'ECONNABORTED' || error.message === "Network Error" || error.message.includes("timeout")) {
            let config = error.config
            // If config does not exist or the retry option is not set, reject
            if(!config || !config.retry) {
                ElMessage.error("请求超时，请稍后重试")
                return Promise.reject('请求超时，请稍后重试');
            }
            // Set the variable for keeping track of the retry count
            config.__retryCount = config.__retryCount || 0
            // Check if we've maxed out the total number of retries
            if(config.__retryCount >= config.retry) {
                // Reject with the error
                ElMessage.error("请求超时，请稍后重试")
                return Promise.reject('请求超时，请稍后重试');
            }
            // Increase the retry count
            config.__retryCount += 1

            // Create new promise to handle exponential backoff
            let backoff = new Promise(function(resolve) {
                setTimeout(function() {
                    resolve()
                }, config.retryDelay || 1)
            })
            // Return the promise in which recalls axios to retry the request
            return backoff.then(function() {
                return service(config)
            })
        } else {
            ElMessage.error(`请求失败:${error.message}`)
            return Promise.reject(`请求失败:${error.message}`);
        }

    }
)

export default service;
