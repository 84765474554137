// +-------------------------------------------------------------
// | Created by WebStorm.
// +-------------------------------------------------------------
// | User: msmile
// +-------------------------------------------------------------
// | Date: 2022/8/18
// +-------------------------------------------------------------
// |Time: 14:05
// +-------------------------------------------------------------

import { createRouter, createWebHashHistory } from "vue-router";
import Promote from '@/router/model/promote'
import {getLocalStorage} from "@/utils/make";
const routes = [
    {
        path: "/",
        redirect: "/dashboard"
    },
    {
        path: "/",
        name: "adminHome",
        component:()=>import("@/views/Home"),
        children:[
            {
                path: "/refresh",
                name: "VRefresh",
                meta: {
                    title: "刷新"
                },
                component: () => import ("@/views/Refresh.vue")
            },
            ...Promote,
        ]
    },
    {
        path: '/login',
        name: "VLogin",
        meta: {
            title: "登陆"
        },
        component: () => import('@/views/Login/Login.vue')
    }
]

const router = createRouter({
    history:createWebHashHistory(),
    routes
})

// 退出后路由重置
export function resetRouter() {
    const newRouter = createRouter({
        history: createWebHashHistory(),
        routes
    })
    router.matcher = newRouter.matcher // reset router
}


router.beforeEach((to,from,next,) => {
    document.title = `${to.meta.title} | 青菜手游推广后台_手游推广代理加盟_聚合手游折扣充值_手游充值青菜价`;
    // 判断是否登陆
    const localStorageData = getLocalStorage();
    if ((!localStorageData.uid || !localStorageData.token) && to.path !== '/login') {
        next('/login');
    } else {
        next();
    }
})

export default router
