import store from '@/store'
export function getLocalStorage() {
    return {
        token: localStorage.getItem('promote_token') || '',
        uid: localStorage.getItem('promote_id') || 0
    }
}

export function getUserInfo() {
    if (store.state.userInfo.length === 0) {
        store.dispatch('getUserInfo')
    }
    return store.state.userInfo.length;
}


/**
 * 图片缩放处理
 * @param {string} res
 * @returns {string}
 */
 export function img_zoom(res) {
    if (res) res += '?x-oss-process=image/resize,p_50';
    return res;
}

/**
 * 清除图片缩放处理
 * @param {string} str
 * @returns {string}
 */
export function img_zoom_clear(str) {
    return str ? str.replace(/[&?]x-oss-process=image\/resize,p_\d+/g, '') : str
}

/**
 * 判断变量是否为空
 * @param {any} res
 * @returns {boolean}
 */
export function empty(res) {
    let flag = true;
    if (res) {
        if (Array.isArray(res)) {
            if (res.length > 0) flag = !flag;
        } else if (res instanceof Object) {
            if (Object.keys(res).length > 0) flag = !flag;
        }
    }
    return flag;
}

/**
 * 添加前导0处理
 * @param n
 * @returns {*|string}
 */
export function formatNumber(n) {
    n = n.toString()
    return n[1] ? n : '0' + n
}

/*
 * 格式化时间
 * format_str 想要展现的时间格式  例如 yyyy-MM-dd hh:mm:ss
 * mtime 时间戳(s) 单位为秒
 */
export function formatTime(format_str, mtime = 0) {
	if (!mtime) {
		mtime = new Date();
	} else {
		mtime = new Date(parseInt(mtime) * 1000);
	}
	var timeObject = {
		"M+": mtime.getMonth() + 1, //月份
		"d+": mtime.getDate(), //日
		"h+": mtime.getHours(), //小时
		"m+": mtime.getMinutes(), //分
		"s+": mtime.getSeconds(), //秒
		"q+": Math.floor((mtime.getMonth() + 3) / 3), //季度
		"S": mtime.getMilliseconds() //毫秒
	};
	if (/(y+)/.test(format_str)) {
		format_str = format_str.replace(RegExp.$1, (mtime.getFullYear() + "").substr(4 - RegExp.$1.length));
	}
	for (var k in timeObject) {
		if (new RegExp("(" + k + ")").test(format_str)) {
			format_str = format_str.replace(RegExp.$1, (RegExp.$1.length == 1) ? (timeObject[k]) : (("00" + timeObject[k]).substr(
				("" + timeObject[k]).length)));
		}
	}
	return format_str;
}

/**
 * 下载文件
 * @param {any} bl
 * @param {String} name
 * @param {String} extension
 */
 export const exportData = (bl, name, extension = 'xlsx') => {
    const blob = new Blob([bl]);
    const fileName = `${name}（${formatTime('yyyy-MM-dd hh:mm:ss')}）.${extension}`;
    const link = document.createElement('a');
    link.download = fileName;
    link.style.display = 'none';
    link.href = URL.createObjectURL(blob);
    document.body.appendChild(link);
    link.click();
    URL.revokeObjectURL(link.href);
    document.body.removeChild(link);
}

/**
 * url文件下载
 * @param {String} url
 * @param {String} name
 * @param {String} extension
 */
 export const downloadUrlData1 = (url, name = null, extension = null) => {
    let url_parse_info = filePathParse(url)
    name = name ?? url_parse_info.filename ?? '没有名字'
    extension = extension ?? url_parse_info.extension ?? 'log'
    const link = document.createElement('a');
    link.download = `${name}.${extension}`;
    link.style.display = 'none';
    link.href = url;
    document.body.appendChild(link);
    link.click();
    URL.revokeObjectURL(link.href);
    document.body.removeChild(link);
}

/**
 * url转bl并下载
 * @param {String} url
 * @param {String} name
 * @param {String} extension
 */
export const downloadUrlData = (url, name = null, extension = null) => {
    let url_parse_info = filePathParse(url)
    name = name ?? url_parse_info.filename ?? '没有名字'
    extension = extension ?? url_parse_info.extension ?? 'log'
    fetch(url)
        .then((response) => response.blob())
        .then((res) => {
            exportData(res, name, extension)
        })
}

/**
 * 文件路径解析，获取路径中的各个部分
 * @param {String} url /www/htdocs/inc/lib.inc.php
 * @param {String} options 默认获取全部，可单独获取：dirname | basename | extension | filename
 * @returns {Object|String} {
 *      dirname: '/www/htdocs/inc',
 *      basename: 'lib.inc.php',
 *      extension: 'php',
 *      filename: 'lib.inc',
 * }
 */
export const filePathParse = (url, options = '') => {
    let doct_idx = url.lastIndexOf(".")
    let slash_idx = url.lastIndexOf("/")

    let info = {
        dirname: url.substring(0, slash_idx),
        basename: url.substring(slash_idx + 1),
        extension: url.substring(doct_idx + 1),
        filename: url.substring(slash_idx + 1, doct_idx),
    }

    if (options !== '') {
        return info[options] ?? ''
    }
    return info;
}

/**
 * 生成一个从 start 到 end 的连续数组
 * @param {Integer} start
 * @param {Integer} end
 * @returns {Array}
 */
export const generateArray = (start, end) => {
    return Array.from(new Array(end + 1).keys()).slice(start)
}
